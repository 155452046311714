import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { RoomType } from '../model/roomType';

@Injectable({
  providedIn: 'root'
})
export class RoomTypeRepository extends BaseRepository<RoomType> {
  constructor(http: HttpClientService) {
    super(http);
  }

  getBaseUrl() {
    return environment.api_uri + '/room-type/';
  }
}
