import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { Equipment } from '../model/equipment';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class EquipmentRepository extends BaseRepository<Equipment> {
  constructor(http: HttpClientService) {
    super(http);
  }

  getBaseUrl() {
    return environment.api_uri + '/equipment/';
  }

  createAndLinkToCheck(entity: any): Observable<Equipment> {
    const url = this.getBaseUrl() + 'create/link-check';

    return this.http.post<Equipment>(url, entity);
  }

  equipmentAndLinkCheck(equipmentId, checkId): Observable<Equipment> {
    const url = this.getBaseUrl() + `${equipmentId}/link-check/${checkId}`;

    return this.http.post<Equipment>(url, {});
  }

  setRoomTypes(equipmentId, roomTypeIds): Observable<Equipment> {
        const url = this.getBaseUrl() + `${equipmentId}/roomTypes`;

        return this.http.post<Equipment>(url, {roomTypes: roomTypeIds});
  }
}
