import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { Site } from '../model/site';

@Injectable({
  providedIn: 'root'
})
export class SiteRepository extends BaseRepository<Site> {
  constructor(http: HttpClientService) {
    super(http);
  }

  addAssistancePhone(siteId : string, data : any){
    let url = this.getBaseUrl()+siteId+'/assistance-phone';
    return this.http.post(url, data);
  }

  removeAssistancePhone(siteId : string, data) {
    let url = this.getBaseUrl()+siteId;
    return this.http.delete(url, data);
  }



  editSite(id, data) {
    let url = this.getBaseUrl() + id;
    return this.http.put(url, data);
  }

  getBaseUrl() {
    return environment.api_uri + '/site/';
  }
}
