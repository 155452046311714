import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { Floor } from '../model/floor';

@Injectable({
  providedIn: 'root'
})
export class FloorRepository extends BaseRepository<Floor> {
  constructor(http: HttpClientService) {
    super(http);
  }

  getBaseUrl() {
    return environment.api_uri + '/floor/';
  }  
}