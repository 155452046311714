import { Site } from "./site";
import {ServiceModule} from './services';
import {Organization} from './organization';

export const RoleUser = {
    2: 'Administrateur',
    3: 'Prestataire',
    4: 'Résident',
    5: 'Gestionnaire de site',
    7: 'Visiteur',
    8: 'Non résident',
    9: 'Élu',
    10: 'Mainteneur'

};

export interface Qr {
    status: string;
    qr: string;
}

export interface User {
    id?: string;
    firstname: string;
    lastname: string;
    society: string;
    phone: string;
    email: string;
    role: string;
    stid_id: string;
    qr?: Qr;
    sites?: Site[];
    assignments?: string[];
    role_description?: string;
    plainPassword?: string;
    services?: ServiceModule[];
    organization: Organization;
}

export interface UserAssignment {
    type: string,
    user?: User,
    assignment?: any
}
export interface CreateUserAssignment {
    type: string,
    user: string
}

export interface CreateUser {
    firstname: string;
    lastname: string;
    society: string;
    phone: string;
    email: string;
    role: string;
    sites?: Site;
    services?: ServiceModule[];
}

export interface EditUser extends CreateUser {
    id?: string;
    users: string[];
}
