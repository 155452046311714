import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { Building } from '../model/building';
import { Floor } from '../model/floor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildingRepository extends BaseRepository<Building> {
  constructor(http: HttpClientService) {
    super(http);
  }

  getBaseUrl() {
    return environment.api_uri + '/building/';
  }

  getFloors(id): Observable<Floor[]> {
    const url = this.getBaseUrl() + id + '/floor';
    return this.http.get<Floor[]>(url);
  }
}