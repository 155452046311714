import {BaseRepository} from '@repository/base.repository';
import {HttpClientService} from '../service/http-client.service';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {ServiceModule} from '../model/services';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})

export class ServicesRepository extends BaseRepository<ServiceModule> {

    constructor(http: HttpClientService) {
        super(http);
    }

    getBaseUrl() {
        return environment.api_uri + '/service/';
    }

    getFullServices(): Observable<ServiceModule[]> {
        const url = this.getBaseUrl() + 'full';
        return this.http.get(url);
    }

    editService(data, id) {
        const url = this.getBaseUrl() + id;

        return this.http.post(url, data);
    }

    serviceExist(id) {
        const url = this.getBaseUrl() + 'check/exist/' + id;

        return this.http.get(url);
    }

    deleteService(id) {
        const url = this.getBaseUrl() + id;

        return this.http.delete(url, {});
    }
}
