import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { TicketChannel } from '../model/ticket-channel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketChannelProviderRepository extends BaseRepository<TicketChannel> {
  constructor(http: HttpClientService) {
    super(http);
  }

  getBaseUrl() {
    return environment.api_uri + '/channel-providers/';
  }
}
