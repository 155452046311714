import { Injectable } from '@angular/core';
import { HttpClientService } from '../service/http-client.service';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './base.repository';
import { Room } from '../model/room';
import { Statement } from '@angular/compiler';
import { RoomStatement } from '../model/statement';

@Injectable({
  providedIn: 'root'
})
export class RoomRepository extends BaseRepository<Room> {
  constructor(http: HttpClientService) {
    super(http);
  }

  getRoomAndLastTicket(id) {
    return this.http.get(this.getBaseUrl() + id + '/tickets', {order_field: 'createdAt', order_type: 'desc', size: 1});
  }

  getStatements(id) {
    return this.http.get(this.getBaseUrl() + id + '/statements');
  }

  getBaseUrl() {
    return environment.api_uri + '/room/';
  }
}
